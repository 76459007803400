import React, {Component} from "react"
import classes from '../styles/headerburger.module.sass'
import Login from '../components/login'
import { menuData } from '../../course_content/meta/MenuData'
import { FaBars, FaTimes } from 'react-icons/fa'
import {Link} from 'gatsby'

class HeaderBurger extends Component{

    constructor(props) {
        super(props);
        this.state = {active: false};
    }
    handleClick = () => {
        this.setState({ active: !this.state.active });
    };



    render () {
        return (
            <div>
                <div className={classes.bars} onClick={this.handleClick}>
                    { !this.state.active && <FaBars/>}
                </div>
                { this.state.active && <div className={classes.bars} onClick={this.handleClick}>
                            <FaTimes/>
                        </div>
                }
                { this.state.active &&
                    <div className={classes.burgermenu} onClick={this.handleClick}>
                        
                        <div className={classes.menucontent}>
                            {menuData.map((item, index) => (
                                <Link to={item.link}>
                                    <h1 className={classes.navlink} key={index}>
                                        {item.title}
                                    </h1>
                                </Link>
                            ))}
                            <div className={classes.loginbutton}>
                                <Login></Login>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

}

export default HeaderBurger