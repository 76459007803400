
import React from 'react'
import classes from '../styles/footer.module.sass'


const Footer = () => {
    

    return (
        <div className={classes.footer}>
            <div className={classes.footercontent}>
                <div className={classes.footertext}>
                    <a className={classes.footertext} href="https://liu.se/en">Linköping University</a><br/>
                    <a className={classes.footertext} href="https://www.ida.liu.se/index.en.shtml">Department of Computer and Information Science</a><br/>
		            <a className={classes.footertext} href="mailto:foundations-of-ml@ida.liu.se">foundations-of-ml@ida.liu.se</a>
                </div>
                <div className={classes.footerpart}>
                    <p className={classes.footertext}>
                    This webpage contains the course materials for the course ETE370 Foundations of Machine Learning.<br/>
                    The content is licensed under <a href="https://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution 4.0 International</a>.<br/>
                    Copyright © 2021, Joel Oskarsson, Amanda Olmin &amp; Fredrik Lindsten
                    </p>
		        </div>
              
            </div>
           
        </div>

    )
}

export default Footer
